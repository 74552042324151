<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 60%;display: flex;">
					<!-- 姓名 -->
					<el-input class="hunt" v-model="designation" placeholder="请输入会员姓名" size="large" @input="orangutan"
						clearable />
					<!-- 编码 -->
					<el-input class="hunt" v-model="serial" placeholder="请输入会员编码" size="large" @input="howl"
						clearable />
					<!-- 派发时间 -->
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始时间"
						end-placeholder="结束时间" size="large" @change="immediUpdate" />
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
			</div>
			<div class="summation">
				<div class="hint">统计 : {{altogether}}条</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:empty-text="texts" :header-cell-style="{ background: '#F5F7FA' }">
				<!-- 会员姓名 -->
				<el-table-column align="center" label="会员姓名" prop="name" :formatter="formatSex"
					:show-overflow-tooltip='true' />
				<!-- 会员编码 -->
				<el-table-column label="会员编码" prop="username" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 操作时间 -->
				<el-table-column label="操作时间" prop="operationTime" align="center" :formatter="tiems"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 更改前日期 -->
				<el-table-column label="更改前日期" prop="beforeTime" align="center" :formatter="tiems"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 更改后日期 -->
				<el-table-column label="更改后日期" prop="afterTime" align="center" :formatter="tiems"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 操作人 -->
				<el-table-column label="操作人" prop="adminName" align="center" 	:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 备注 -->
				<el-table-column label="备注" prop="reason" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				v-model:currentPage="currentPage" :page-size="pagesize" :total="altogether"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		</el-card>
	</div>
</template>
<script>
	import {
		Delete,
		EditPen,
		Search,
		Plus
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onActivated
	} from 'vue';
	import {
		delayLog,
	} from '../../utils/api'
	import qs from 'qs'
	import moments from 'moment'
	import {
		ElMessage
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	export default {
		name: "delayLog",
		setup() {
			const route = useRoute();
			// 搜索加载
			// 时间选择
			let value1 = ref('')
			const formative = ref(false)
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					values.value = ''
					topic.value = ''
					texts.value = '加载中'
					// branches.value = 6
					// currentPage.value = 1
					list()
				}
				// console.log(translate.value)
				// console.log(route.params.userId)
			})
			onMounted(() => {
				list()
			})
			// 列表内容显示
			let texts = ref('加载中')
			// 搜索框值变化清空
			let orangutan = () => {
				// if (topic.value == '' && values.value == '') {
				// 	list()
				// }
			}
			// // 下拉清空
			// let empty = () => {
			// 	if (topic.value == '' && values.value == '') {
			// 		list()
			// 	}
			// }
			// 搜索
			let topic = ref('')
			let search1 = () => {
				formative.value = true
				search()
			}
			let search = () => {
				let data = {
					//活动id
					associationId: sessionStorage.getItem('activityIds'),
					limit: branches.value,
					page: currentPage.value,
					// 开始时间 结束时间
					startTime: a.value,
					endTime: out.value,
					// 会员姓名
					name: designation.value,
					// 会员编码
					username: serial.value,
				}
				console.log(data)
				delayLog(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						altogether.value = res.data.data.manualExtensionPage.totalCount
						filterTableData.value = res.data.data.manualExtensionPage.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}

					}
				})
			}
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				pagesize.value = size
				branches.value = size
				if (designation.value !== '' || serial.value !== '' || a.value !== '' || b
					.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				currentPage.value = size
				// branches.value+=50
				if (designation.value !== '' || serial.value !== '' || a.value !== '' || b
					.value !== '') {
					search()
				} else {
					list()
				}
			}
			// 时间转换
			let tiems = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format().substring(0, 10)
				}
			}
			// 总条数
			let altogether = ref(null)
			// 工会动态列表
			let branches = ref(6)
			const list = () => {
				let data = {
					//活动id
					associationId: sessionStorage.getItem('activityIds'),
					limit: branches.value,
					page: currentPage.value,
				}
				delayLog(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						altogether.value = res.data.data.manualExtensionPage.totalCount
						filterTableData.value = res.data.data.manualExtensionPage.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 姓名输入框值
			let designation = ref('')
			// 会员编码框
			let serial = ref('')
			// 时间
			let a = ref('')
			let b = ref('')
			let c = ref('')
			let out = ref('')


			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
					c.value = moments(b.value * 1000).format().substring(0, 10)
					out.value = new Date(c.value + ' ' + '23:59:59').getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
					c.value = ''
					out.value = ''
				}
				console.log(e)
			}
			// 头部搜索
			let values = ref('')
			// 编辑跳转
			const copyreader = (id) => {
				console.log(id)
				router.push({
					name: 'dtedit',
					params: {
						UserId: 1
					}
				});
				sessionStorage.setItem('new', id)
			}
			// 路由跳转
			const router = useRouter()
			// 头部添加跳转
			let append = () => {
				router.push('/inform')
			}
			// 列表数据循环
			const filterTableData = ref([])
			return {
				// 搜索加载
				search1,
				formative,
				texts,
				orangutan,
				// 搜索
				search,
				topic,
				// 分页
				handleSizeChange,
				handleCurrentChange,
				altogether,
				pagesize,
				currentPage,
				tiems,
				values,
				// 编辑跳转
				copyreader,
				// 头部组织添加跳转
				append,
				// 循环列表数据
				filterTableData,
				// 时间选择
				value1,
				immediUpdate, //时间选择
				designation,//会员姓名
				serial,//会员编码
			};
		},
		components: {
			Delete,
			EditPen,
			Search,
			Plus
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	.summation {
		display: flex;
		justify-content: space-between;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	.el-select--large {
		width: 20%;
		// margin-left: 10px;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 20%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
</style>
<style>
	/* ::-webkit-scrollbar{display:none} */
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>